.tr-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    /* line-height: 21px; */
    -webkit-line-clamp: 2;
    min-height: 48px;
    -webkit-box-orient: vertical;
}

.tr-w-2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-width: 220px;
}

.tr-w-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.ql-editor {
    height: 200px;
    max-height: 200px;
    overflow: auto;
}

/*  */
.kTqvuD div:first-child {
    font-size: revert !important;
    position: relative;
    top: 2px
}

/*  */
.atricle-text p {
    margin: 10px 0;
    font-weight: 400;
}


/*  */
.slick-prev:before {
    background: url(./../images/icons/ArrowPrevious.svg);
    background-size: contain;
}

.slick-next:before {
    background: url(./../images/icons/ArrowNext.svg);
    background-size: contain;
}

.slick-arrow:before {
    content: "" !important;
    width: 180% !important;
    height: 180% !important;
    position: absolute;
    top: 0;
    left: 0;
}

.slick-dots {
    bottom: auto !important;
}